// import React from "react"
// import type { GatsbyBrowser } from "gatsby"

// export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] = () => {
//   window.addEventListener(
//     'scroll',
//     appendThirdPartyScripts,
//     appendScriptsOnScrollOptions
//   )
// }
// const appendScriptsOnScrollOptions = { once: true }

// // Callback to append scripts
// const appendThirdPartyScripts = () => {
// //   appendScriptTagManager()
//   appendScript(scripts.segment.__html)
//   appendScript(scripts.rakuten.__html)
//   appendScript(scripts.bing.__html)
//   appendScript(scripts.tiktok.__html)
//   appendScript(scripts.reddit.__html)
//   appendScript(scripts.snapchat.__html)
//   appendScript(scripts.twitch.__html)
//   appendScript(scripts.accessibe.__html)
//   // Variable set to reference as a check that callback ran
//   sessionStorage.setItem(loadedScripts, 'true')
// }
// const appendScript = (scriptInnerHTML) => {
//   const scriptElement = document.createElement('script')
//   scriptElement.innerHTML = scriptInnerHTML
//   document.head.appendChild(scriptElement)
// }
// const loadedScripts = 'loadedScripts'

// /*export function replaceHydrateFunction() {
//     return (element, container, callback) => {
//         ReactDOM.render(element, container, callback)
//     }
// } */

export const onClientEntry = () => {
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.scope.includes(window.location.origin)) {
          console.log('Unregistering old service worker:', registration);
          registration.unregister();
        }
      });
    });
  }
};
